import React, {useRef, useState, useLayoutEffect} from "react"
import { useWindowSize } from "../hooks/useWindowSize"
import * as styles from "../styles/sun.module.scss"
import Eye from "./Eye"

const Sun = () => {
  const windowSize = useWindowSize()

  const boxRef = useRef();
  const [globalCoords, setGlobalCoords] = useState({x: 0, y: 0})
  const [posSun, setPosSun] = useState({x: 0, y: 0});

  function getOffsetTop( elem ) {
        var offsetTop= 0;
        do {
            if ( !isNaN( elem.offsetTop ) )
            {
                offsetTop += elem.offsetTop;
            }
        } while( elem = elem.offsetParent );
        return offsetTop;
  }

  function getOffsetLeft( elem ) {
        var offsetLeft= 0;
        do {
            if ( !isNaN( elem.offsetLeft ) )
            {
                offsetLeft += elem.offsetLeft;
            }
        } while( elem = elem.offsetParent );
        return offsetLeft;
  }
  
  const [loaded, setLoaded] = useState(false)

  const handleWindowMouseMove = event => {
    setGlobalCoords({
      x: event.clientX,
      y: event.clientY,
    });
  };

  const getPosition = () => {
    setPosSun({
        x : getOffsetLeft(boxRef.current),
        y: getOffsetTop(boxRef.current) - window.scrollY
    });
  };

  useLayoutEffect(() => {
    window.addEventListener('mousemove', handleWindowMouseMove);
    window.addEventListener('scroll', (getPosition));
    window.addEventListener('resize', getPosition);

    return () => {
      window.removeEventListener('mousemove', handleWindowMouseMove);
      window.removeEventListener('resize', getPosition);
      window.removeEventListener('scroll', getPosition);
    };

  }, []);

  useLayoutEffect(() => {
      getPosition()
  }, []);

  const posLeft = globalCoords.x < posSun.x ? globalCoords.x / posSun.x * .5 * 40 : ((globalCoords.x - posSun.x) * .5) / windowSize.width * 40 + 20
  const postTop = globalCoords.y < posSun.y ? globalCoords.y / posSun.y * .5 * 40 : ((globalCoords.y - posSun.y) * .5) / windowSize.height * 40 + 20

  const eyeStyle = {
    top: `${postTop + 30}%`,
    left: `${posLeft + 30}%`
  }

  const pupilStyle = {
    transform: `translate(${posLeft - 20}%, ${postTop - 20}%)`,
  }

  return (
    <>
        <div className={styles.circle} ref={boxRef}>
            <Eye
                eyeStyle={eyeStyle}
                pupilStyle={pupilStyle}
            />
            <Eye 
                eyeStyle={eyeStyle}
                pupilStyle={pupilStyle}
            />

        </div>
    </>
    
  )
}

export default Sun
