import React from "react"
import * as styles from "../styles/contact.module.scss"
import Sun from "./Sun"

const Contact = ({lang}) => {

  lang = lang.slice(0, 2)

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <h2>Let’s talk</h2>

        <Sun />

        <div>
          <p>
            134 - 140 rue d’Aubervilliers<br/>
            75019 Paris
          </p>
          <p>
            + 33 6 69 66 61 62 - Central Office<br/>
            hello@thesunproject.fr
          </p>
          <p>
            <a href="https://calendly.com/thesunproject/30-minutes-meeting?month=2022-09" className={styles.roundedButton} alt="reserver un appel" target="blank">{lang === "fr" ? "réserver un appel" : "book a call"}</a>
          </p>
        </div>
      </div>
      <form className={styles.column} action="https://getform.io/f/0048f340-c7d3-4f76-8241-d64d5b878d28" method="POST">
        <input type="text" required name="name" placeholder={lang === "fr" ? "votre nom*" : "your name*"}></input>
        <input type="email" required name="email" placeholder={lang === "fr" ? "votre email*" : "your email*"}></input>
        <input type="text" name="company" placeholder={lang === "fr" ? "entreprise" : "company"}></input>
        <input type="text" required name="subject" placeholder={lang === "fr" ? "sujet*" : "subject*"}></input>
        <textarea  placeholder="message*" name="message" required rows="5" ></textarea>
        <button className={styles.roundedButton}>{lang === "fr" ? "envoyer" : "send"}</button>
      </form>
    </div>
  )
}

export default Contact
