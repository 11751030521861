import React from "react"
import * as styles from "../styles/sun.module.scss"

const Eye = ({eyeStyle, pupilStyle}) => {

  return (
        <svg className={styles.eye} style={eyeStyle} viewBox="0 0 38 78.66">
            <path d="M18.75,78.66c-3.76,0-8.1-2.15-11.5-7.53-2.48-3.93-4.23-9.18-5.5-16.52C-1.32,36.95-.32,19.61,4.5,7.02c.4-1.03,1.55-1.55,2.58-1.15,1.03,.4,1.55,1.55,1.15,2.58-4.57,11.94-5.49,28.51-2.55,45.47,1.18,6.81,2.75,11.6,4.95,15.08,2.84,4.51,7.05,6.66,10.23,5.23,3.51-1.57,5.96-6.62,7.49-10.28,5.41-13.17,6.97-27.56,4.49-41.59-.61-4.18-1.86-8.15-3.74-11.86-2.78-5.02-6.62-6.87-8.99-6.43-3.41,.62-5.46,4.98-6.38,7.53-1.49,4.15-2.24,9.16-2.9,15.41-.12,1.1-1.11,1.9-2.2,1.78-1.1-.12-1.89-1.1-1.78-2.2,.69-6.54,1.49-11.82,3.12-16.35C12.06,4.44,15.41,.85,19.4,.13c4.64-.84,9.96,2.57,13.24,8.5,2.11,4.16,3.5,8.55,4.16,13.1,2.6,14.72,.96,29.88-4.74,43.76-1.95,4.66-4.8,10.27-9.55,12.4-1.13,.51-2.41,.78-3.76,.78Z"/>
            <path style={pupilStyle}  d="M16.7,61.64c4.63,2.83,9.48-4.64,10.83-16.68s-1.31-24.08-5.94-26.91-9.48,4.64-10.83,16.68c-1.35,12.03,1.31,24.08,5.94,26.91"/>
        </svg>
  )
}

export default Eye
